import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Hero from "../../components/hero/hero";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const MakeAppointment = () => {
  const title = "Make An Appointment";
  const description =
    "Save time and schedule an appointment with a specialist to discuss your banking, investments, home loan or small business needs.";

  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/customer-service/make-appointment/hero-make-appointment-01-2500.jpg" }
      ) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/customer-service/make-appointment/hero-make-appointment-01-1920.jpg" }
      ) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/customer-service/make-appointment/hero-make-appointment-01-1200.jpg" }
      ) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/customer-service/make-appointment/hero-make-appointment-01-992.jpg" }
      ) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/customer-service/make-appointment/hero-make-appointment-01-768.jpg" }
      ) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/customer-service/make-appointment/hero-make-appointment-01-600.jpg" }
      ) {
        ...heroImageFragmentXS
      }
    }
  `);

  const heroData = {
    id: "make-appointment-hero",
    ...getHeroImgVariables(imgData, ["xs", "sm", "md", "lg", "xl", "xxl"]),
    alt: "calendar showing appointment"
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/customer-service/make-appointment"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      title: "Make An Appointment",
      active: true
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Hero {...heroData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Schedule an Appointment</h1>
        <p>
          We've always believed relationships are the heart of good business. We'd like to sit down with you, learn
          about your unique needs, and work together to find the right solution. Schedule your own time to open new
          accounts, talk about your services, or plan for your future. Personal bankers are available to assist you with
          your checking, savings, mortgages and more.
        </p>
        <p>
          To make an appointment, simply find the branch most convenient to you and use the &ldquo;make an
          appointment&rdquo; link to book your time.
        </p>
        <div className="mt-4 text-center">
          <Link className="btn btn-primary" to="/locations">
            Find a Branch
          </Link>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default MakeAppointment;
